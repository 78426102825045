<template>
    <div>
        <ul class="article-items published" v-if="articles">
            <li class="item" v-for="item in articles" :key="item.id">
                <div class="pic">
                    <img :src="util.reImg(item.img)" alt />
                </div>
                <div class="item-cont">
                    <div class="hd">
                        <div class="opera">
                            <el-popover
                                placement="top-start"
                                content="点击查看详情"
                                width="40"
                                trigger="hover"
                            >
                                <el-button
                                    slot="reference"
                                    class="edit-btn"
                                    @click="detail(item.id)"
                                >
                                    <i class="jk-iconkongzhitai-chakanxiangqing"></i>
                                </el-button>
                            </el-popover>

                            <!-- <el-popover
                                placement="top-start"
                                content="点击编辑文章"
                                width="40"
                                trigger="hover"
                            >
                                <el-button class="edit-btn" slot="reference" @click="edit(item.id)">
                                    <i class="jk-icon-bianjilanse"></i>
                                </el-button>
                            </el-popover>-->

                            <el-popover
                                placement="top-start"
                                content="点击删除文章"
                                width="40"
                                trigger="hover"
                            >
                                <el-button class="del-btn" slot="reference" @click="del(item.id)">
                                    <i class="jk-icon-lajitong"></i>
                                </el-button>
                            </el-popover>
                        </div>

                        <i :class="{'jk-icon-huo':item.is_hot==1}"></i>
                        <h4
                            class="title"
                            v-text="item.title"
                            :title="item.title"
                            @click="view(item.id)"
                            style="cursor:pointer"
                        ></h4>
                    </div>
                    <div class="bd">
                        <div class="bd-left">
                            <div class="tag">
                                <span v-text="item.cate_id.value" class="cate"></span>
                                <span v-if="item.is_jh==1" :class="{'jinghua': item.is_jh==1}">精华</span>
                                <span
                                    v-for="p in item.position_arr"
                                    :key="p"
                                    v-text="p"
                                ></span>
                                <span v-for="tag in item.tag" :key="tag" v-text="tag"></span>
                            </div>
                            <div class="guide">
                                <span>
                                    <i class="jk-icon-liulanliang"></i>
                                    {{item.view_count}}
                                </span>
                                <span>
                                    <i class="jk-icon-dianzanshu"></i>
                                    {{item.like_count}}
                                </span>
                            </div>
                        </div>
                        <div class="bd-right">
                            <div class="download" v-if="item.annex_count>0">
                                <p v-if="item.download_point_count>0||item.download_price>0">
                                    下载所需点券：
                                    <em
                                        class="orange"
                                    >{{item.download_point_count}}点({{item.download_price}}元)</em>
                                </p>
                                <p v-else>
                                    <em class="orange">免费下载</em>
                                </p>

                                <span>
                                    下载次数：
                                    <em class="blue">{{item.download_count}}次</em>
                                </span>
                            </div>
                            <time class="date">{{item.create_time}}</time>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <p v-else class="empty" style="height: 300px;line-height: 200px;font-size:14px;color:#999;">暂无分享</p>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "PublishedList",
    components: {},
    data() {
        return {};
    },
    props: ["articles"],
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        del(e) {
            this.$emit("del", e);
        },
        edit(e) {
            this.$emit("edit", e);
        },
        view(e) {
            this.$emit("view", e);
        },
        detail(e) {
            this.$router.push("/article/detail/" + e);
        }
    }
};
</script>

