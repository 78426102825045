<template>
    <div>
        <ul class="article-items published" v-if="articles">
            <li class="item" v-for="item in articles" :key="item.id">
                <div class="pic">
                    <img :src="util.reImg(item.img)" alt />
                </div>
                <div class="item-cont">
                    <div class="hd">
                        <span class="status">审核中</span>

                        <i :class="{'jk-icon-huo':item.is_hot==1}"></i>
                        <h4
                            class="title"
                            v-text="item.title"
                            :title="item.title"
                            @click="view(item.id)"
                            style="cursor:pointer;"
                        ></h4>
                    </div>
                    <div class="bd">
                        <div class="bd-left">
                            <div class="tag">
                                <span v-text="item.cate_id.value" class="cate"></span>
                                <span v-if="item.is_jh==1" :class="{'jinghua': item.is_jh==1}">精华</span>
                                <span
                                    v-for="position in item.position_arr"
                                    :key="position"
                                    v-text="position"
                                ></span>
                                <span v-for="(t,index) in item.tag" :key="index" v-text="t"></span>
                            </div>
                            <div class="guide"></div>
                        </div>
                        <div class="bd-right">
                            <div class="download" v-if="item.annex_count>0">
                                <p v-if="item.download_point_count>0||item.download_price>0">
                                    下载所需点券：
                                    <em
                                        class="orange"
                                    >{{item.download_point_count}}点({{item.download_price}}元)</em>
                                </p>
                                <p v-else>
                                    <em class="orange">免费下载</em>
                                </p>

                                <span>
                                    下载次数：
                                    <em class="blue">{{item.download_count}}次</em>
                                </span>
                            </div>
                            <time class="date">{{item.create_time}}</time>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <p v-else class="empty" style="height: 300px;line-height: 200px;font-size:14px;color:#999;">暂无分享</p>
    </div>
</template>
<script>
export default {
    name: "UnderReview",
    data() {
        return {};
    },
    methods: {
        view(e) {
            this.$emit("view", e);
        }
    },
    props: ["articles"]
};
</script>
