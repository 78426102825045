<template>
    <div class="article-panel">
        <div class="white-bg">
            <h3 class="g-title" style="margin-top: -20px;">我的资源分享</h3>
            <div class="article-group mt-20">
                <div class="article-hd">
                    <span class="avatar">
                        <img :src="util.reImg(avatar)" />
                    </span>
                    <h4 class="name" v-text="userInfo.info.name"></h4>
                    <div class="guide">
                        <span>
                            <strong>{{userInfo.info.subscribe_count}}人</strong>
                            <em>关注人数</em>
                        </span>
                        <span>
                            <strong>{{pCount}}篇</strong>
                            <em>我的文章</em>
                        </span>
                    </div>
                    <el-button class="release-btn" @click="edit(0)" v-if="isShow">
                        <i class="jk-icon-tianjia"></i>发布资源
                    </el-button>
                    <el-button class="release-btn" @click="showMsg()" v-else>
                        <i class="jk-icon-tianjia"></i>发布资源
                    </el-button>
                </div>

                <div class="article-bd pd-0" v-loading="loading">
                    <el-tabs @tab-click="toggle" v-model="activeName">
                        <el-tab-pane name="one">
                            <span slot="label">
                                已发布
                                <em>({{pCount}})</em>
                            </span>
                            <el-row class="filter-wrap">
                                <el-button
                                    :class="{'is-active':sortVal=='desc'}"
                                    @click="sort('desc')"
                                >
                                    按时间倒序
                                    <i class="jk-icon-jiantouxia"></i>
                                </el-button>
                                <el-button
                                    :class="{'is-active':sortVal=='asc'}"
                                    @click="sort('asc')"
                                >
                                    按时间顺序
                                    <i class="jk-icon-jiantoushang"></i>
                                </el-button>
                            </el-row>
                            <published-list
                                :articles="articleList"
                                v-on:del="del"
                                v-on:edit="edit"
                                v-on:view="view"
                            ></published-list>
                        </el-tab-pane>

                        <el-tab-pane name="two">
                            <span slot="label">
                                草稿箱
                                <em>({{dCount}})</em>
                            </span>
                            <draft-box
                                :articles="articleList"
                                v-on:del="del"
                                v-on:edit="edit"
                                v-on:publish="publish"
                                v-on:view="view"
                            ></draft-box>
                        </el-tab-pane>

                        <el-tab-pane name="three">
                            <span slot="label">
                                审核中
                                <em>({{rCount}})</em>
                            </span>
                            <under-review :articles="articleList" v-on:view="view"></under-review>
                        </el-tab-pane>
                    </el-tabs>

                    <pagination
                        v-if="pageTotal >0"
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :pageTotal="pageTotal"
                        @current-change="newPage"
                    ></pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PublishedList from "./module/PublishedList.vue";
import DraftBox from "./module/DraftBox.vue";
import UnderReview from "./module/UnderReview.vue";
import Pagination from "@/components/common/Pagination.vue";
import { mapActions, mapState } from "vuex";
export default {
    name: "index",
    metaInfo() {
        return {
            title: "资源分享 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        PublishedList, //已分布
        DraftBox, //草稿箱
        UnderReview, //审核中
        Pagination //分页
    },
    data() {
        return {
            articleList: {}, //文章列表
            pCount: 0, // 已发布资源数
            dCount: 0, //草稿箱文章数
            rCount: 0, //待审核文章数
            review: 4, //文章分类（4:已发布；2:草稿箱；3:待审核）
            sortVal: "desc", //排序值
            currentPage: 1, //当前页面
            pageSize: 10, //每页数据条数
            pageTotal: 0, //数据总量
            activeName: "one",
            loading: false,
            avatar: "",
            isShow : false
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        releaseBtnClick() {
            this.$router.push("/user/article/edit");
        },
        getlist() {
            let _this = this;
            this.post(
                "/article/article/lists",
                {
                    review: _this.review,
                    order: _this.sortVal,
                    page: _this.currentPage,
                    limit: _this.pageSize
                },
                function(res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.articleList = res.data.list;
                        _this.pageTotal = res.data.count;
                    }
                }
            );
        },

        toggle(tab) {
            //tab切换
            let _this = this;
            _this.loading = true;
            _this.articleList = [];
            _this.currentPage = 1; //第一页开始加载
            switch (Number(tab.index)) {
                case 0: //已发布
                    _this.review = 4;
                    setTimeout(() => {
                        _this.getlist();
                    }, 10);
                    break;
                case 1: //草稿箱
                    _this.review = 2;
                    setTimeout(() => {
                        _this.getlist();
                    }, 10);
                    break;
                case 2: //待审核
                    _this.review = 3;
                    setTimeout(() => {
                        _this.getlist();
                    }, 10);
                    break;
                default:
                    break;
            }
        },

        sort(e) {
            this.loading = true;
            this.sortVal = e;
            this.getlist();
        },

        getCount() {
            let _this = this;
            this.post("/article/article/article_count", {}, function(res) {
                if (res.code == 200) {
                    _this.pCount = res.data.p_count;
                    _this.dCount = res.data.d_count;
                    _this.rCount = res.data.r_count;
                }
            });
        },

        newPage(page) {
            let _this = this;
            _this.currentPage = page;
            _this.getlist();
        },
        //删除数据
        del(id) {
            let _this = this;
            this.$confirm("删除后将不可恢复, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.post(
                        "/article/article/delete_article",
                        {
                            id: id
                        },
                        function(res) {
                            if (res.code == 200) {
                                _this.getlist(); //获取文章列表
                                _this.getCount(); //获取文章数量
                                _this.$message({
                                    type: "success",
                                    message: "删除成功"
                                });
                                return false;
                            }
                            _this.$message.error("删除失败");
                        }
                    );
                })
                .catch(() => {});
        },
        //编辑
        edit(id) {
            this.$router.push("/user/articleEdit/" + id);
        },
        //查看
        view(id) {
            this.$router.push("/user/articleView/" + id);
        },
        //发布
        publish(id) {
            //提交表单
            let _this = this;
            this.$confirm("确定申请发布吗，申请后将不可编辑?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.post(
                        "/article/article/publish_article",
                        {
                            id: id,
                            review: 3 //添加到待审核
                        },
                        function(res) {
                            if (res.code == 200) {
                                _this.$message({
                                    type: "success",
                                    message: "发布申请成功，请等待管理员审核"
                                });
                                _this.activeName = "three";
                                _this.getCount(); //刷新文章数
                                _this.toggle({ index: 2 }); //刷新列表
                            } else {
                                _this.$message.error(res.msg);
                            }
                        }
                    );
                })
                .catch(() => {});
        },
        toggleTab() {
            this.loading = true;
            let tabId = Number(this.$route.params.id);
            switch (tabId) {
                case 1:
                    this.activeName = "one";
                    break;
                case 2:
                    this.activeName = "two";
                    break;
                case 3:
                    this.activeName = "three";
                    break;
                default:
                    break;
            }
            this.toggle({ index: tabId - 1 });
        },
        showMsg(){
            this.$message.error("仅入驻的工程极客，或实名认证后的雇主可以发布资源分享");
        }
    },
    created() {
        this.toggleTab();
        this.getCount();
        if (!this.userInfo.info.avatar) {
            this.avatar =
                this.websiteConfigs.sourceUrl + "/upload/image/geek_avatar.png";
        } else {
            this.avatar = this.userInfo.info.avatar;
        }
        
        let UserInfos = JSON.parse(localStorage.getItem('vuex'));
        if (UserInfos.userInfo.attestation && UserInfos.userInfo.attestation.is_pass == 1){
            this.isShow = true;
        }
    },
    watch: {
        $router() {
            this.toggleTab();
        }
    }
};
</script>

<style lang="less" scroped>
@import "../../../styles/article.less";
.empty {
    text-align: center;
    font-size: 16px;
    margin-top: 10%;
}
.article-items .tag {
    height: 30px;
    line-height: 30px;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
}
.article-items .tag {
    width: 100%;
}
.article-items .tag span.cate {
    background: #587ddc;
    color: #fff;
}
.article-items span + span {
    margin-left: 4px;
}
.article-panel .article-items .ftr {
    height: 50px;
}
.article-panel .article-items .bd .bd-left {
    width: 70% !important;
}
.article-panel .article-items .bd .bd-right {
    width: 30% !important;
}
.article-panel .article-items .item .pic {
    width: 100px;
    overflow: hidden;
    float: left;
}

.article-panel .article-items .item .item-cont {
    width: 770px !important;
    padding: 0px;
    overflow: hidden;
    float: right;
}
.article-panel .article-items .item .item-cont .hd {
    margin: 0;
}
.pd-0 {
    padding: 0 !important;
}
.article-group {
    border: none !important;
}
.el-tabs__nav-scroll {
    padding: 0 0;
}
.article-panel .article-bd .el-tabs__item {
    padding: 0 !important;
}
.article-panel .article-bd .el-tabs__item span {
    padding: 0 20px !important;
}
</style>




