<template>
    <div>
        <ul class="article-items" v-if="articles">
            <li class="item" v-for="item in articles" :key="item.id">
                <div class="pic">
                    <img :src="util.reImg(item.img)" />
                </div>
                <div class="item-cont">
                    <div class="hd">
                        <h4
                            class="title"
                            v-text="item.title"
                            :title="item.title"
                            @click="view(item.id)"
                            style="cursor:pointer"
                        ></h4>
                        <div class="opera">
                            <el-button class="edit-btn" @click="edit(item.id)">
                                <i class="jk-icon-bianjilanse"></i>
                            </el-button>
                            <el-button class="del-btn" @click="del(item.id)">
                                <i class="jk-icon-lajitong"></i>
                            </el-button>
                        </div>
                    </div>

                    <div class="tag">
                        <span v-text="item.cate_id.value" class="cate"></span>
                        <span v-for="tag in item.tag" :key="tag" v-text="tag"></span>
                    </div>

                    <div class="ftr">
                        <span>{{item.create_time}}</span>
                        <el-button @click="publish(item.id)">发布</el-button>
                    </div>
                </div>
            </li>
        </ul>
        <p v-else class="empty" style="height: 300px;line-height: 200px;font-size:14px;color:#999;">暂无分享</p>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "DraftBox",
    components: {},
    data() {
        return {};
    },
    props: ["articles"],
    methods: {
        del(e) {
            this.$emit("del", e);
        },
        edit(e) {
            this.$emit("edit", e);
        },
        publish(e) {
            this.$emit("publish", e);
        },
        view(e) {
            this.$emit("view", e);
        }
    }
};
</script>
<style lang="less">
.article-items .hd .title {
    float: left;
}
</style>
